@tailwind base;
@tailwind components;
@tailwind utilities;

.containerLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  height: 150px;
  width: 150px;
  border: 1px solid black;
  animation: rotate_3922 1.2s linear infinite;
  background-color: #9b59b6;
  background-image: linear-gradient(red, yellow, blue);
}

.containerLoader span {
  position: absolute;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  background-color: #9b59b6;
  background-image: linear-gradient(#9b59b6, #84cdfa, #5ad1cd);
}

.containerLoader span {
  filter: blur(20px);
}
.containerLoader::after {
  content: "";
  position: absolute;
  top: 50px;
  left: 50px;
  right: 50px;
  bottom: 50px;
  background-color: black;
  border: solid 1px yellow;
  border-radius: 50%;
}

@keyframes rotate_3922 {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
